import {useRouter} from 'next/dist/client/router'
import {useState} from 'react'
import {useUser} from '../../../common/auth/authHooks'
import {trpc} from "../../../../utils/trpc";
import {randomString} from "../../../../utils/utils";
import { getRedirectBaseUrl } from '../../../../config/config';

export function useTwitterLink() {
  const {data, refetchUser: refetch, isLoading} = useUser()
  const router = useRouter()

  const [connecting, setConnecting] = useState<boolean>(false)
  const [disconnecting, setDisconnecting] = useState<boolean>(false)

  const connect = () => {
    if (connecting) {
      return
    }

    setTimeout(() => {
      setConnecting(false)
    }, 1000 * 60)
    setConnecting(true)

    const state = randomString(10);
    const codeVerifier = randomString(10);

    localStorage.setItem('twitter_link_state', state)
    localStorage.setItem('twitter_link_code_verifier', codeVerifier)
    localStorage.setItem('twitter_link_redirect_url', router.asPath)

    const baseUrl = getRedirectBaseUrl()
    const redirectUrlTemplate = process.env.NEXT_PUBLIC_TWITTER_LINK_REDIRECT_URL as string
    const redirectUrl = redirectUrlTemplate.replace('BASE_TWITTER_URI', encodeURIComponent(baseUrl + '/verify/twitter'))

    void router.push(
      `${redirectUrl}&state=${state}&code_challenge=${codeVerifier}`
    )
  }

  const removeTwitterIdMut = trpc.useMutation('user.rmTwitter', {
    onSuccess: () => refetch().then(() => setDisconnecting(false)),
    onError: () => refetch().then(() => setDisconnecting(false)),
  })
  const disconnect = () => {
    setDisconnecting(true)
    removeTwitterIdMut.mutate()
  }

  return {
    twitterId: data?.twitterId,
    twitterUsername: data?.twitterUsername,
    connecting,
    disconnecting,
    connect,
    disconnect,
    isLoading,
  }
}
