import {TriangleDownIcon} from '@chakra-ui/icons'
import {Button, ButtonProps, HStack} from '@chakra-ui/react'
import React, {FC, useCallback, useEffect, useMemo, useRef, useState,} from 'react'
import {useSocialLink} from "./discordLinkHooks";
import {DiscordConnectButton} from "./DiscordConnectButton";
import {useWallet} from "@solana/wallet-adapter-react";
import {FaCheck, FaDiscord} from "react-icons/fa";
import {useUser} from "../../../common/auth/authHooks";
import {userConnectButton} from "../common";
import { SignMessageToLoginButton } from '../../../dApp/components/SignMessageToLoginButton';

export const DiscordLinkMultiButton: FC<ButtonProps> = ({ children, ...props }) => {
  const { discordId, discordUsername, connect, disconnect, disconnecting } = useSocialLink()
  const { isAuthed } = useUser()
  const { connected } = useWallet()
  const [copied, setCopied] = useState(false)
  const [active, setActive] = useState(false)
  const ref = useRef<HTMLUListElement>(null)

  const content = useMemo(() => {
    if (children) return children
    if (!discordUsername) return discordUsername
    return discordUsername.slice(0, 4) + '..' + discordUsername.slice(-4)
  }, [children, discordUsername])

  const copyAddress = useCallback(async () => {
    if (discordUsername) {
      await navigator.clipboard.writeText(discordUsername)
      setCopied(true)
      setTimeout(() => setCopied(false), 1000)
    }
  }, [discordUsername])

  const openDropdown = useCallback(() => {
    setActive(true)
  }, [])

  const closeDropdown = useCallback(() => {
    setActive(false)
  }, [])


  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const node = ref.current

      // Do nothing if clicking dropdown or its descendants
      if (!node || node.contains(event.target as Node)) return

      closeDropdown()
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, closeDropdown])

  if (!connected)
    return <></>

  if (!isAuthed)
    return <SignMessageToLoginButton />

  if (!discordId)
    return <DiscordConnectButton/>

  return (
    <div className='wallet-adapter-dropdown'>
      <Button
        disabled={disconnecting}
        aria-expanded={active}
        style={{ pointerEvents: active ? 'none' : 'auto', ...props.style }}
        onClick={openDropdown}
        leftIcon={<FaDiscord />}
        rightIcon={<TriangleDownIcon />}
        css={userConnectButton}
        {...props}
      >
        {content}
      </Button>
      <ul
        aria-label='dropdown-list'
        className={`wallet-adapter-dropdown-list ${
          active && 'wallet-adapter-dropdown-list-active'
        }`}
        ref={ref}
        role='menu'
      >
        <li
          onClick={copyAddress}
          className='wallet-adapter-dropdown-list-item'
          role='menuitem'
        >
          <HStack alignItems='center'>
            {copied ? (<>Copied <FaCheck style={{marginLeft: '10px'}}/> </>) : (<>Copy</>)}
          </HStack>
        </li>
        <li
          onClick={connect}
          className='wallet-adapter-dropdown-list-item'
          role='menuitem'
        >
          Change
        </li>
        <li
          onClick={() => {
            disconnect()
            closeDropdown()
          }}
          className='wallet-adapter-dropdown-list-item'
          role='menuitem'
        >
          Disconnect
        </li>
      </ul>
    </div>
  )
}
