import {Button, ButtonProps} from '@chakra-ui/react'
import React, {FC, MouseEventHandler, useCallback, useMemo} from 'react'
import {FaTwitter} from "react-icons/fa";
import {userConnectButton} from "../common";
import {useTwitterLink} from "./twitterLinkHooks";

export const TwitterConnectButton: FC<ButtonProps> = ({
  children,
  disabled,
  onClick,
  ...props
}) => {
  const {twitterId, connect, connecting} = useTwitterLink()

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (onClick) onClick(event)
      if (!event.defaultPrevented) connect()
    },
    [onClick, connect]
  )

  const content = useMemo(() => {
    if (children) return children
    if (connecting) return 'Connecting ...'
    if (twitterId) return 'Connected'
    return 'Connect Twitter'
  }, [children, connecting, twitterId])

  return (
    <Button
      disabled={disabled || connecting}
      leftIcon={<FaTwitter />}
      onClick={handleClick}
      css={userConnectButton}
      {...props}
    >
      {content}
    </Button>
  )
}
